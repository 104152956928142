interface ITranslate {
    [engKey: string] : string;
}

let translator:Map<string, ITranslate> = new Map<string, ITranslate>();

let zh_CN: ITranslate = {
    "Home": "主页",
    "About": "关于",
    "Location": "位置",
    "Get Connected": "连接",
    "FAQ": "常见问题",
    "Language": "语言",
    "Loading...": "载入中...",
    "Welcome to AS139589": "欢迎来到 AS139589",
    "All rights reserved.": "保留所有权利。",
    "Peering Request Form": "互联申请表",
    "First name": "名字",
    "Last name": "姓氏",
    "Please input your first name.": "请输入您的名字。",
    "Please input your last name.": "请输入您的姓氏。",
    "Email": "电子邮箱",
    "Valid email is required.": "需要有效的电子邮箱。",
    "Please input your AS number.": "请输入您的自治系统号码。",
    "Network name": "网络名称",
    "Please input your network name.": "请输入您的网络名称。",
    "Valid network prefix is required.": "需要有效的网络前缀",
    "Tunnel location": "隧道地点",
    "Beijing": "北京",
    "Frankfurt": "法兰克福",
    "Los Angeles": "洛杉矶",
    "Tokyo": "东京",
    "Other": "其他",
    "Tunnel type": "隧道类型",
    "Back": "返回",
    "Next": "下一步",
    "Our Connections": "连接状况",
    "Upstream": "上游",
    "Downstream": "下游",
    "Network Resources": "网络资源",
    "IXP": "交换中心",
    "Name": "名称",
    "Exchange": "交换中心",
    "Country": "国家",
    "City": "城市",
    "Detail": "详细",
    "Dismiss": "关闭"
};

translator.set("zh_CN", zh_CN);

function _T(lang: string, msg: string): string {
    if(translator.has(lang)){
        let ld = translator.get(lang);
        let res = ld![msg];
        if(res !== undefined)
            return res;
        return msg;
    }
    return msg;
}

export default _T;