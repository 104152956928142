import React from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import cx from 'classnames';
import $ from "jquery";
import _T from './lang';

import 'popper.js'
import 'bootstrap/js/dist/util'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import * as Waves from './mdbootstrap/js/vendor/waves'

import { MatchProps } from './interfaces';
import bs from "./styles/styles.module.scss";
import "./styles/styles.scss";
import "./styles/header.css";

class Header extends React.Component<RouteComponentProps<MatchProps>> {

    componentDidMount() {
        $('.'+bs["navbar-collapse"]+'>li>a').on('click', function(){
            ($('.'+bs["navbar-collapse"]) as any).collapse('hide');
        });
        Waves.init();
    }

    render() {
        let lang = this.props.match.params.lang;
        let linkClass = cx(bs["nav-link"], "waves-effect");
        return (
            <nav className={cx(bs.navbar, bs["navbar-expand-md"], bs["navbar-light"], "white", bs["bg-light"])}>
                <NavLink to={"/"+lang+"/home"} className={bs["navbar-brand"]}>
                    <img alt="" src="/logo192.png" className={cx(bs["d-inline-block"], bs["align-top"])} width="30" height="30"/>
                    <span style={{paddingLeft: "10px"}}>GTDev Network</span>
                </NavLink>
                <button className={cx(bs["navbar-toggler"], "waves-effect")} type="button" data-toggle="collapse"
                        data-target="#navbarContent" aria-controls="navbarContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className={bs["navbar-toggler-icon"]}></span>
                </button>
                <div className={"collapse "+bs["navbar-collapse"]} id="navbarContent">
                    <li className={cx(bs["mr-auto"], bs["navbar-nav"])}>
                        <NavLink to={"/"+lang+"/home"} className={linkClass} activeClassName={bs.active}>{_T(lang,"Home")}</NavLink>
                        <NavLink to={"/"+lang+"/about"} className={linkClass} activeClassName={bs.active}>{_T(lang,"About")}</NavLink>
                        <NavLink to={"/"+lang+"/location"} className={linkClass} activeClassName={bs.active}>{_T(lang,"Location")}</NavLink>
                        <NavLink to={"/"+lang+"/getConnected"} className={linkClass} activeClassName={bs.active}>{_T(lang,"Get Connected")}</NavLink>
                        <NavLink to={"/"+lang+"/FAQ"} className={linkClass} activeClassName={bs.active}>{_T(lang,"FAQ")}</NavLink>
                    </li>
                    <li className={bs["navbar-nav"]}>
                        <div className={"dropdown "+bs["nav-item"]}>
                            <div className={cx(linkClass, bs["dropdown-toggle"])+" dropdown-toggle"} id="navbarLanguage"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" tabIndex={-1} style={{paddingLeft: "0px"}}>
                                {_T(lang,"Language")}</div>
                            <div className={"dropdown-menu "+bs["dropdown-menu"]} aria-labelledby="navbarLanguage">
                                <NavLink to={"/en/"+this.props.match.params.tab} className={bs["dropdown-item"]}>English</NavLink>
                                <NavLink to={"/zh_CN/"+this.props.match.params.tab} className={bs["dropdown-item"]}>中文</NavLink>
                            </div>
                        </div>
                    </li>
                </div>
            </nav>
        )
    }
}

export default withRouter(Header);
