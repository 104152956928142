import React, {Suspense} from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom'
import _T from './lang'
import cntStyle from './content.module.css'
import Header from "./header";
import { MatchProps } from './interfaces';

const Home = React.lazy(() => import('./home/home'));
const About = React.lazy(() => import('./about/about'));
const Location = React.lazy(() => import('./location/location'));
const GetConnected = React.lazy(() => import('./getConnected/getConnected'));
const Faq = React.lazy(() => import('./faq/faq'));

type LoaderProps = { lang: string };
const Loader = ({lang}: LoaderProps) => {
  return (
      <div id="content" className={cntStyle.LoaderHeader}>
        <div className={cntStyle.Loader}>
          <img src={process.env.PUBLIC_URL + '/loader.gif'} alt="Preloading animation" className=""/>
          <p>{ _T(lang, "Loading...") }</p>
        </div>
      </div>
  );
};

const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
        <Route exact path="/" render={() => (
            <Redirect to="/en/home"/>
        )}/>
        <Route path="/:lang/:tab" component={Header} />
        <Suspense fallback={<Route path="/:lang/:tab" component={( match: MatchProps) => (
            <Loader lang={match.lang} /> )}/>}>
          <Switch>
            <Route path="/:lang/home" component={Home} />
            <Route path="/:lang/about" component={About} />
            <Route path="/:lang/location" component={Location} />
            <Route path="/:lang/getConnected" component={GetConnected} />
            <Route path="/:lang/FAQ" component={Faq} />
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
